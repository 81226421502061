<template>
  <v-container fluid>
    <v-row>
      <navigate-buttons @clikButton="openModalNewExpense"></navigate-buttons>
      <v-col cols="12" class="mb-6">
        <monthly-obligations></monthly-obligations>
      </v-col>
      <v-col cols="12">
        <pendency-obligations></pendency-obligations>
      </v-col>
      <dialog-new-expense></dialog-new-expense>
      <dialog-tax-reminder></dialog-tax-reminder>
      <passwordPattern v-model="openDialog" v-if="openDialog"></passwordPattern>
      <alert-certificate />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { authComputed, authMethods } from '@state/helpers'
const DialogNewExpense = () =>
  import('@src/components/dialogs/dialog-new-expense.vue')
const navigateButtons = () =>
  import('@src/components/dashboard/navigate-buttons.vue')
const monthlyObligations = () =>
  import('@src/components/dashboard/monthly-obligations.vue')
const PendencyObligations = () =>
  import('@src/components/dashboard/pendency-obligations.vue')
const dialogTaxReminder = () =>
  import('@src/components/dialogs/dialog-tax-reminder.vue')
const passwordPattern = () =>
  import('@src/components/changePassword/newPasswordPattern.vue')
const alertCertificate = () =>
  import('@/components/certificate/alert-certificate.vue')

export default {
  name: 'index-dashboard',
  components: {
    DialogNewExpense,
    navigateButtons,
    monthlyObligations,
    PendencyObligations,
    dialogTaxReminder,
    passwordPattern,
    alertCertificate,
  },
  data: () => ({
    open: false,
    urlVideo: 'https://www.youtube.com/watch?v=9C4sTCH_hOw',
  }),
  methods: {
    ...authMethods,
    ...mapActions({
      dialogsChangeNewExpense: 'dialogs/changeNewExpense',
      dialogsChangeVideoShow: 'dialogs/changeVideoShow',
    }),
    openModalNewExpense() {
      this.dialogsChangeNewExpense(true, {})
    },
    setFirstLoginToFalse() {
      this.setFirstLogin()
    },
    openVideoTour() {
      this.dialogsChangeVideoShow({ status: true, url: this.urlVideo })
    },
  },
  computed: {
    ...authComputed,
    openDialog() {
      if (this.currentUser) {
        return this.currentUser.passwordIsPattern ? false : true
      }
      return false
    },
  },
  mounted() {
    if (this.currentUser.isFirstLogin) {
      this.openVideoTour()
      this.setFirstLogin()
    }
  },
}
</script>
<style scoped lang="sass"></style>
